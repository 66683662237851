.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
  margin-top: 140px;
  width:100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1200px){
  .App-header {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: black;
    margin-top: 115px;
  }
}

@media only screen and (min-width: 651px) and (max-width: 1199px){
  .App-header {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: black;
    margin-top: 80px;
  }
}

.personalizedBody{
  display: flex;
  flex-direction: column;
  width:100%;
}
.personalizedMain{
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 50px;
}
.personalizedTitle{
  margin: 2vh;
  font-weight: lighter;
}
.personalizedSubtitle{
  padding-top: 10px;
  font-size: calc(6px + 2vmin);
  color: black;
  font-weight: lighter;
}
.personalized_items{
  display: flex;
  flex-direction: column;
  flex: 2;
}
.personalized_item_info{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid lightgrey;
  text-decoration: none;
  color: black;
}
.personalized_item_info:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  cursor: pointer;
}
.personalized_title_info_header{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-decoration: none;
  color: black;
}
.roomContainer{
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.personalized_item_info_header{
  display: flex;
  flex-direction: column;
  margin-bottom:10px;
}
.personalized_item_info_header_title{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #D9D2C3;
}
.personalized_item_image{
  width: 60%;
  align-self: center;
}
.personalized_item_title{
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 0px;
  text-decoration: underline;
  font-size: calc(12px + 1vmin);
  text-align: left;
  padding-left:20px;
}
.personalized_item_description{
  font-weight: lighter;
  padding-top: 15px;
  font-size: calc(14px + 1vmin);
  text-align:left;
  padding-left:20px;
}
.personalized_item_description_sub{
  font-weight: lighter;
  padding-top: 15px;
  font-size: calc(10px + 1vmin);
}
.personalized_plus_minus{
  margin-left:10px;
  padding: 5px 10px 5px 10px;
  border: 1px solid grey;
  border-radius: 10px;
  cursor: pointer;
}
mark{
  background: white;
  font-weight: bold;
}

@media only screen and (min-width: 1000px){
  .personalizedMain{
    display: flex;
    flex-direction: row;
    width:100%;
    flex: 1;
    flex-wrap: wrap;
    padding-top: 50px;
  }
}

@media only screen and (min-width: 651px){
  .personalized_item_info{
    display: flex;
    flex-direction: row;
    text-align: left;
    padding-left: 100px;
    padding-right: 20px;
    text-decoration: none;
    color: black;
    
  }
  .personalized_item_info:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    cursor: pointer;
  }
  .personalized_title_info_header{
    display: flex;
    flex-direction: row;
    text-align: left;
    padding-left: 100px;
    padding-right: 20px;
    text-decoration: none;
    color: black;
  }
  .personalized_item_image{
    width: 10%;
    min-width: 200px;
    align-self: flex-start;
  }
  .personalizedSubtitle{
    padding-top: 10px;
    font-size: calc(8px + 2vmin);
    color: black;
  }

}

