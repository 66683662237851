.searchBody{
  display: flex;
  flex-direction: column;
  width:100%;
}
.searchMain{
  display: flex;
  flex-direction: column;
  width:100%;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 50px;
}
.searchTitle{
  margin: 2vh;
  font-weight: lighter;
}
.searchSubtitle{
  padding-top: 10px;
  font-size: calc(6px + 2vmin);
  color: black;
  font-weight: lighter;
}
.search_items{
  display: flex;
  flex-direction: column;
  flex: 2;
}
.search_item_info{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid lightgrey;
}
.search_item_info:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.search_item_image{
  width: 30%;
  align-self: center;
}
.search_item_description{
  font-weight: lighter;
  padding-top: 20px;
  font-size: calc(14px + 1vmin);
}
mark{
  background: white;
  font-weight: bold;
}

@media only screen and (min-width: 1000px){
  .searchMain{
    display: flex;
    flex-direction: row;
    width:100%;
    flex: 1;
    flex-wrap: wrap;
    padding-top: 50px;
  }
}

@media only screen and (min-width: 651px){
  .search_item_info{
    display: flex;
    flex-direction: row;
    text-align: left;
    padding-left: 100px;
    padding-right: 20px;
  }
  .search_item_info:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
  }
  .search_item_image{
    width: 10%;
    min-width: 200px;
    align-self: flex-start;
  }
  .searchSubtitle{
    padding-top: 10px;
    font-size: calc(0px + 2vmin);
    color: black;
  }

}
