:root{
  --cartSize: 2px;
}
.header-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: white;
  font-size: calc(10px + 2vmin);
}

.header{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: calc(10px + 2vmin);
  border-bottom: 1px solid lightgrey;
}

.CartLogo{
  display: flex;
  flex-direction: row;
  padding:20px;
  margin-right: 30px;
  width: var(--cartSize);
  height: var(--cartSize);
  position: relative;
  align-items: center;
  justify-content: center;
}
.cart{
  position: absolute;
  z-index: 2;
  left: -5px;
  top: 2px;
}
.cartNumber{
  position: absolute;
  z-index:3;
}
.mw-logo{
  display: flex;
  font-family: 'Poppins';
  font-size: calc(10px + 1vmin);
  align-items: flex-end;
  padding-left: 20px;
}
.title-menu{
  display: flex;
  font-family: 'Poppins';
  font-size: calc(8px + 1vmin);
  align-items: flex-end;
  justify-content: flex-end;
}
.title-link{
  text-decoration: none;
  color: black;
}
.title-link:hover{
  border-bottom: 1px solid black;
}

.fg--search {
  background: white;
  position: relative;
  margin-left: -44px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 60%;
}

.fg--search input {
  width: 100%;
  padding: 10px 50px 10px 10px;
  display: block;
  font-size: 18px;
}

.fg--search button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: -64px;
  padding: 10px 20px;
  z-index: 2;
}

.fg--search input:focus + button .fa-search {
  color: red;
}

.fa-search{
  color: black;
}

.linkSearch{
  text-decoration: none;
}

.dropbtn {
  background-color: white;
  font-size: calc(10px + 1vmin);
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  display: none;
  position: absolute;
  background-color: white;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  cursor: pointer;
  display: block;
}

.dropdown-content a:hover {border-bottom: 1px solid black;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: white;}

@media only screen and (min-width: 1200px){
  .mw-logo{
    display: flex;
    font-family: 'Poppins';
    font-size: calc(10px + 1vmin);
    align-items: flex-end;
    padding-left: 40px;
  }
}
@media only screen and (min-width: 651px){
  .fg--search {
    background: white;
    position: relative;
    margin-left: -44px;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 30%;
  }
}
