.body{
  display: flex;
  flex-direction: column;
  width:100%;
}
.main{
  display: flex;
  flex-direction: column;
  width:100%;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 50px;
}
.items{
  display: flex;
  flex-direction: column;
  flex: 2;
}
.item_info{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid lightgrey;
}
.item_image{
  width: 30%;
  align-self: center;
}
.summary{
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 300px;
  min-height: 800px;
  background-color: #ededed;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 25px;
}
.summaryInfo{
  font-size: calc(8px + 2vmin);
  text-align: center;
  font-weight: lighter;
  line-height: 1.5;
  margin-top: 20px;
}
.quoteInfo{
  font-size: calc(6px + 2vmin);
  text-align: center;
  font-weight: lighter;
  line-height: 1.5;
  padding: 10px;
}
.title{
  margin: 2vh;
  font-weight: lighter;
}
.subtitle{
  padding-top: 10px;
  font-size: calc(6px + 2vmin);
  color: black;
}

.link{
  color: black;
}
.link:hover{
  border-bottom: 1px solid black;
}

@media only screen and (min-width: 1000px){
  .main{
    display: flex;
    flex-direction: row;
    width:100%;
    flex: 1;
    flex-wrap: wrap;
    padding-top: 50px;
  }
}

@media only screen and (min-width: 651px){
  .item_info{
    display: flex;
    flex-direction: row;
    text-align: left;
    padding-left: 100px;
    padding-right: 20px;
  }
  .summaryInfo{
    font-size: calc(3px + 2vmin);
  }
  .quoteInfo{
    font-size: calc(0px + 2vmin);
    padding: 10px;
  }
  .item_image{
    width: 10%;
    align-self: flex-start;
  }
  .subtitle{
    padding-top: 10px;
    font-size: calc(0px + 2vmin);
    color: black;
  }

}
