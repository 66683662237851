.productpage{
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  align-content: center;
  width:280px;
  height:450px;
  margin:0px;
  padding:40px;
}
.productpage:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.productpagestatic{
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  align-content: center;
  width:280px;
  height:450px;
  margin:0px;
  padding:40px;
}
