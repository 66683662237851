.button {
  font-size: calc(8px + 1vmin);
  background-color: black; /* Green */
  border: none;
  color: white;
  padding: 12px 64px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.button:hover{
  background-color: grey;
  cursor: pointer;
}

@media only screen and (min-width: 651px){
  .button {
    font-size: calc(5px + 1vmin);
    background-color: black; /* Green */
    border: none;
    color: white;
    padding: 12px 64px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }
}
