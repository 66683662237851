.decrement{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid grey;
}
.decrement:hover{
  cursor:pointer;
}
.increment{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid grey;
}
.increment:hover{
  cursor:pointer;
}
.quantity{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid grey;
  font-size: calc(6px + 1vmin);
}
.remove{
  padding-left: 20px;
  font-weight: normal;
  font-size: calc(6px + 1vmin);
  align-self: center;
}
.remove:hover{
  cursor: pointer;
}
