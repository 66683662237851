.mainFooter{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.borderFooter{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 20px;
  border-top: 1px solid lightgrey;
  width: 90%;
}
.noBorderFooter{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;
}
.categoryFooter{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.subscribeFooter{
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  width: 90%;
}
.itemFooter{
  padding: 5px 0px 5px 0px;
  font-weight: lighter;
}
.emailTitle{
  font-size:calc(20px + 2vmin);
  text-align: center;
  line-height: 1.6;
}
.emailSubTitle{
  line-height: 1.6;
  padding-bottom: 30px;
}
input[type=email]{
    width: 100%;
    padding: 6px 10px;
    margin: 8px 0;
    display: inline-block;
    outline: 0;
    border-width: 0 0 2px;
    border-radius: 0;
    box-sizing: border-box;
}

.iconcontainer{
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 20px;
}
.bubble_icon{
    display: inline-block;
    background: gray;
    color: white;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 8px;
    text-align: center;
}
.bubble_icon:hover{
    background: #D9D2C3;
    color: black;
}
.icon{
    margin: 30% auto;
}
.text-center{
  text-align: center;
}
.valign-top{
  vertical-align: top;
}

.top{
  cursor: pointer;
  width: 100%;
}
.top:hover{
  font-weight: bold;
}

@media only screen and (min-width: 1200px){
  .borderFooter{
    width: 90%;
  }
  .noBorderFooter{
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .subscribeFooter{
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .emailTitle{
    text-align: left;
  }
  .emailSubTitle{
    padding-right: 30px;
  }
}
@media only screen and (min-width: 651px){
  .borderFooter{
    width: 90%;
  }
  .noBorderFooter{
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .subscribeFooter{
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .emailTitle{
    text-align: left;
  }
  .emailSubTitle{
    padding-right: 30px;
  }
}
