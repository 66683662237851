.carouselContainer {
  overflow:hidden;
  position:relative;
  text-align: center;
  font-size: 16px;
  width: 100%;
  height: 50vh;
}

.cover{
  height: 50vh;
  object-fit: cover;
}
