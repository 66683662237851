.maindiv{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top:40px;
}

.showproductimage{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  order: 1;
}
.thumbnails{
  display: flex;
  flex-direction: row;
}
.thumbnail{
  margin: 10px;
  width: 52px;
  height: 52px;
  overflow: hidden;
}

.showProductInfo{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 300px;
  max-width: 40%;
  font-size: calc(8px + 1vmin);
  order: 3;
}
.noShowProductInfo{
  display: none;
  font-size: calc(8px + 1vmin);
  order: 3;
}

.checkAdded {
  font-size: calc(8px + 1vmin);
  margin-left: 20px;
  color: green;
  align-self: flex-end;
}

.similarItems{
  display: flex;
  flex-direction: column;
  font-size: calc(15px + 1vmin);
  font-weight: lighter;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 100px;
  padding-top: 15px;
  border-top: 1px solid lightgrey;
}

@media only screen and (min-width: 1200px){
  .maindiv{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top:40px;
  }
  .showproductimage{
    display: flex;
    justify-content: center;
    max-width: 40%;
    order: 0;
  }
  .showProductInfo{
    display: none;
    font-size: calc(8px + 1vmin);
    order: 3;
  }
  .noShowProductInfo{
    display: block;
    font-size: calc(8px + 1vmin);
    order: 3;
  }
}

@media only screen and (min-width: 651px){
  .checkAdded {
    font-size: calc(5px + 1vmin);
    margin-left: 20px;
    color: green;
    align-self: flex-end;
  }
}
