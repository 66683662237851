.resource_main{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:40px;
}
.resource_title{
  margin: 2vh;
}
.resource_sub_title{
  font-size:calc(8px + 2vmin);
  font-weight: normal;
  width: 85%;
  text-align:left;
  line-height: 1.6;
  margin-top: 2vh;
}
 .resource_paragraph{
   font-size:calc(8px + 2vmin);
   font-weight: lighter;
   width: 85%;
   text-align: left;
   line-height: 1.6;
 }
